import { combineReducers } from 'redux'
import { createResponsiveStateReducer } from 'redux-responsive'
import appConfig from 'reducers/appConfigReducer'
import auth from 'reducers/authReducer'
import authModalIsOpen from 'reducers/authModalIsOpenReducer'
import availableElection from 'reducers/availableElectionReducer'
import ballot from 'reducers/ballotReducer'
import ballotPlaces from 'reducers/ballotPlacesReducer'
import candidatesById from 'reducers/candidatesByIdReducer'
import candidatesByPosition from 'reducers/candidatesByPositionReducer'
import choiceElections from 'reducers/choiceElectionsReducer'
import currentCandidate from 'reducers/currentCandidateReducer'
import currentIssue from 'reducers/currentIssueReducer'
import currentMeasure from 'reducers/currentMeasureReducer'
import currentPosition from 'reducers/currentPositionReducer'
import districts from 'reducers/districtsReducer'
import election from 'reducers/electionReducer'
import elections from 'reducers/electionsReducer'
import parties from 'reducers/electionPartiesReducer'
import electionProgression from 'reducers/electionProgressionReducer'
import electionCenter from 'reducers/electionCenterReducer'
import endorsedCandidates from 'reducers/endorsedCandidatesReducer'
import fetchCounter from 'reducers/fetchCounterReducer'
import filters from 'reducers/filtersReducer'
import form from 'reducers/formReducer'
import issuesByPosition from 'reducers/issuesByPositionReducer'
import isTimeTraveling from 'reducers/timeTravelReducer'
import lead from 'reducers/leadReducer'
import maptvSelections from 'reducers/maptvSelectionsReducer'
import measures from 'reducers/measuresReducer'
import mapResults from 'reducers/normalizedPositionReducer'
import notifications from 'reducers/notificationsReducer'
import officeHolders from 'reducers/officeHoldersReducer'
import pingCount from 'reducers/pingCountReducer'
import pollingDays from 'reducers/pollingDaysReducer'
import pollingPlaces from 'reducers/pollingPlacesReducer'
import positions from 'reducers/positionsReducer'
import positionsByCounty from 'reducers/positionsByCountyReducer'
import positionsByState from 'reducers/positionsByStateReducer'
import registration from 'reducers/registrationReducer'
import reminderChannels from 'reducers/reminderChannelsReducer'
import recruitmentSelections from 'reducers/recruitmentReducer'
import requestBallotSelections from 'reducers/requestBallotSelectionsReducer'
import searchResults from 'reducers/searchResultsReducer'
import selections from 'reducers/selectionsReducer'
import selectedTags from 'reducers/selectedTagsReducer'
import sendEmail from 'reducers/sendEmailReducer'
import sharedBallot from 'reducers/sharedBallotReducer'
import showVoterGuideAlert from 'reducers/showVoterGuideAlertReducer'
import showGlobalAlert from 'reducers/showGlobalAlertReducer'
import showLeadBar from 'reducers/showLeadBarReducer'
import showShareableBallotBanner from 'reducers/showShareableBallotBannerReducer'
import showSignUpPrompt from 'reducers/showSignUpPromptReducer'
import states from 'reducers/statesReducer'
import stepStack from 'reducers/stepStackReducer'
import subscriptionCall from 'reducers/subscriptionReducer'
import usStates from 'reducers/usStatesReducer'
import vbmReceiver from 'reducers/vbmReceiverReducer'
import votingPlan from 'reducers/votingPlanReducer'
import { AppConfig } from 'appConfig'

export default (config: AppConfig) => {
  // Shared reducers
  let reducers = {
    appConfig,
    auth,
    authModalIsOpen,
    availableElection,
    ballot,
    ballotPlaces,
    browser: createResponsiveStateReducer({
      extraSmall: 479,
      small: 767,
      medium: 991,
      large: 1099,
      extraLarge: 1150,
    }),
    candidatesById,
    candidatesByPosition,
    choiceElections,
    currentCandidate,
    currentIssue,
    currentMeasure,
    currentPosition,
    districts,
    election,
    electionCenter,
    electionProgression,
    elections,
    endorsedCandidates,
    fetchCounter,
    form,
    issuesByPosition,
    isTimeTraveling,
    lead,
    maptvSelections,
    measures,
    notifications,
    officeHolders,
    parties,
    pingCount,
    pollingDays,
    pollingPlaces,
    positions,
    positionsByCounty,
    positionsByState,
    registration,
    reminderChannels,
    requestBallotSelections,
    selections,
    sharedBallot,
    showVoterGuideAlert,
    showGlobalAlert,
    showLeadBar,
    showShareableBallotBanner,
    showSignUpPrompt,
    states,
    stepStack,
    usStates,
    vbmReceiver,
    votingPlan,
    subscriptionCall,
    sendEmail,
  }

  if (config.enableSuperSlateCard) {
    reducers = Object.assign({}, reducers, {
      filters,
      selectedTags,
    })
  }

  if (config.featureFlags.find(value => value === 'ELECTION_CENTER_SEARCH')) {
    reducers = Object.assign({}, reducers, { searchResults })
  }

  if (config.featureFlags.find(value => value === 'EXPLORE_BY_MAP')) {
    reducers = Object.assign({}, reducers, { mapResults })
  }

  if (config.featureFlags.find(value => value === 'RECRUITMENT_ENGINE')) {
    reducers = Object.assign({}, reducers, { recruitmentSelections })
  }
  return combineReducers(reducers)
}
