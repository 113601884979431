import qs from 'qs'

import * as types from 'actions/constants'
import apiFetch from './apiFetch'

export const invalidateCandidates = positionId => ({
  type: types.INVALIDATE_POSITION_CANDIDATES,
  positionId,
})

const receivePositionCandidates = (payload, positionId) => ({
  type: types.RECEIVE_POSITION_CANDIDATES,
  payload,
  positionId,
  receivedAt: Date.now(),
})

const requestPositionCandidates = positionId => ({
  type: types.REQUEST_POSITION_CANDIDATES,
  positionId,
})

const fetchCandidates =
  (positionId, options, candidateIdWhitelist) => async dispatch => {
    dispatch(requestPositionCandidates(positionId))

    const params = qs.stringify(options)
    const response = await dispatch(
      apiFetch(`/ballot/positions/${positionId}/candidates?${params}`),
    )

    if (!response.ok) {
      return
    }

    const candidates = await response.json()
    let filteredCandidates = candidates
    if (filteredCandidates) {
      if (candidateIdWhitelist) {
        filteredCandidates = candidates.filter(
          c => candidateIdWhitelist.indexOf(c.id) !== -1,
        )
      }
      return dispatch(receivePositionCandidates(filteredCandidates, positionId))
    }
  }

const shouldFetchCandidates = (state, positionId) => {
  const candidates = state.candidatesByPosition[positionId]

  if (!candidates) {
    return true
  }

  if (candidates.isFetching) {
    return false
  }

  return candidates.didInvalidate
}

export default (positionId, options) => async (dispatch, getState) => {
  if (shouldFetchCandidates(getState(), positionId)) {
    const {
      appConfig: { candidateIdWhitelist },
    } = getState()

    return dispatch(
      fetchCandidates(
        positionId,
        options,
        candidateIdWhitelist ? candidateIdWhitelist[positionId] : null,
      ),
    )
  }
}
